<template>
  <div  class="component-fullwidth">
    <div class="flex-container flex-full no-padding no-margin">
      <div class="elem-flex">
        <div class="title-page">
          Talents Search
          <div class="other">
            <div class="cont-new-job-button">
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="applicant-search-cont radius">
      <div class="search-box">
        <div
          class="picto-search"
          @click="search"
        >
          <img
            :src="require('@/assets/pictos/picto_search_darkpurple.svg')"
          >
        </div>
        <div class="search-bar">
          <input v-model="searchTerms.applicantName" @keydown.enter="search"/>
        </div>
         <div class="btn-green radius" @click="search">New&nbsp;Search</div>
      </div>
      <div class="option-select">
        <div class="offers" v-if="offers.length">
          <div class="title-section-search">Offers</div>
          <multi-select v-model="searchTerms.offerIds" :dataSwitch="offers" />
        </div>
        <div class="applicantStatus">
          <div class="title-section-search">Status</div>
          <multi-select v-model="searchTerms.applicantStatuses" :dataSwitch="statuses" />
        </div>
      </div>
      <br/>

    </div>
    <div class="results-cont" id="scroll-result-applicant">
      <applicant
        v-for="res in results"
        :key="res._id" :applicant="res"
        :clickMethod="() => openApplicant(res)"
      />

      <div v-if="searched && results.length === 0">
        No Results
      </div>

    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import MultiSelect from '@/components/utilityPartials/selectMulti.vue';
import ApplicantProcess from '@/components/EmployeeComponents/JobManagement/processPartials/applicantProcess.vue';
import applicant from '../../../components/EmployeeComponents/applicantSearch/_applicant.vue';

/**
* Search View for applicants
* applicantName offerId applicantStatus
*/
export default {
  name: 'ApplicantSearch',
  components: {
    MultiSelect,
    applicant,
  },
  data() {
    return {
      searched: false,
      results: [],
      searchTerms: {
        applicantName: '',
        offerIds: [],
        applicantStatuses: [],
      },
      scroll: {},
    };
  },
  computed: {
    statuses() {
      return [
        {
          label: 'New',
          value: 'initial',
        },
        {
          label: 'Reject',
          value: 'rejected',
        },
        {
          label: 'Current',
          value: 'approved',
        },
        {
          label: 'Running',
          value: 'process',
        },
        {
          label: 'Hired',
          value: 'hired',
        },
        ...this.$store.state.company.columns,
      ];
    },
    offers() {
      return this.$store.state.company.offers.map(
        (offer) => ({ label: offer.name, value: offer._id }),
      );
    },
  },
  mounted() {
    if (!this.$store.getters['company/offers'].length) {
      this.$store.dispatch('company/getOffers');
      this.scroll = new PerfectScrollbar('#scroll-result-applicant', {
        wheelPropagation: false,
        suppressScrollX: true,
      });
      this.scroll.update();
    }
  },
  methods: {
    search() {
      this.searched = true;
      const searchBody = {};
      Object.keys(this.searchTerms).forEach((key) => {
        if (this.searchTerms[key]) {
          searchBody[key] = this.searchTerms[key];
        }
      });
      this.$api.post('company/searchApplicants', searchBody).then((res) => {
        this.results = res.data;
      });
    },
    openApplicant(app) {
      this.$modal.hideAll();
      this.$modal.show(ApplicantProcess,
        {
          applicant: app.applicant,
          offerIdProp: app.offerId,
          stepHide: true,
          processComp: ApplicantProcess,
          modalName: 'applicant-info',

        }, {
          name: 'applicant-info',
          width: '25%',
          height: '70vh',
          scrollable: true,
          adaptive: true,
          classes: ['overflow'],
        });
    },

  },
  watch: {
    'searchTerms.applicantStatuses': {
      handler() {
        this.search();
        this.scroll.update();
      },
    },
    'searchTerms.offerIds': {
      handler() {
        this.search();
        this.scroll.update();
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.applicant-search-cont{
  display: flex;
  flex-direction: column;
  padding: 1vh;
  background-color: white;
  border: .1vh solid $logo-color;
  .search-box{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 2vh;
    .picto-search {
      position: absolute;
      width: 2vh;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    .search-bar{
      width: 100%;
      margin-right: 2vh;
      border-bottom: 1px solid black;
      input{
        width: calc(100% - 2vh);
        background-color: $red_color;
        border-width:  0px;
        position: relative;
        background-color: transparent;
        padding-bottom: 0.5vh;
        padding-top: 0.5vh;
        padding-left: 4vh;
        background-image: none;
      }
    }
  }
  .option-select{
    display: flex;
    flex-direction: row;
    width: 100%;
    .title-section-search{
      font-size: 1.6vh;
      margin-bottom: 1vh;
    }
    .offers{
      width: 60%;
    }
    .applicantStatus{
      width: 40%;
    }
  }
}
  .results-cont{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 2vh;
    width: calc(100% - 1vh);
    max-height: 48vh;
    overflow: hidden;
  }

</style>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.component-fullwidth{
  .applicant-search-cont{
    .option-select{
      .offers, .applicantStatus{
        .select-switch{
          .elem-select-switch{
            border: 1px solid $green-color;
            box-sizing: border-box;
            &.selected{
              background-color: $logo_color;
              border: 1px solid $logo_color;
            }
          }
        }
      }
    }
  }
}
</style>
