<template>
  <div class="select-switch">
    <div class="elem-select-switch radius box-shadow"
      v-for="(elem, index) in options"
      :key="index"
      :class="{selected: elem.selected}"
      @click="() => handleSelect(elem)"
    >

      <span>{{elem.label}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: ['dataSwitch', 'value'],
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.options = this.dataSwitch.map((option) => {
      // eslint-disable-next-line no-param-reassign
      option.selected = false;
      if (this.value.indexOf(option.value) > -1) {
        // eslint-disable-next-line no-param-reassign
        option.selected = true;
      }
      return option;
    });
  },
  methods: {
    handleSelect(elem) {
      // eslint-disable-next-line no-param-reassign
      elem.selected = !elem.selected;
      this.updateModel();
    },
    updateModel() {
      this.$emit('input', this.options.filter((val) => val.selected).map((option) => option.value));
    },
  },
  watch: {
    value(newValue) {
      newValue.forEach((val) => { this.options.find((opt) => opt.value === val).selected = true; });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .select-switch{
    width: calc(100% - 2vh);
    padding-right: 2vh;
    display: flex;
    flex-wrap: wrap;
    .elem-select-switch{
      width: calc(calc(100% - 2vh) / 3);
      margin-right: 1vh;
      margin-bottom: 1vh;
      cursor: pointer;
      span{
        display: block;
        padding: 1vh;
      }
      &:nth-child(3n){
        margin-right: 0;
      }
      &:hover{
        background-color: $green-color;
      }
      &.selected{
        padding: 0;
        color: #ffffff;
      }
    }
  }
  // .select-switch{
  //   width: 100%;
  //   display:flex;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: flex-start;
  //   flex-wrap: wrap;
  //   .elem-select-switch{
  //     font-size: $fnt-14;
  //     width: calc(calc(calc(100% - 3vh) / 4) - 4vh);
  //     text-align: center;
  //     padding: 1.25vh 2vh;
  //     cursor: pointer;
  //     margin-right: 1vh;
  //     background-color: #ffffff;
  //     &:last-child{
  //       margin-right: 0;
  //     }
  //     &.selected{
  //       color: #ffffff;
  //       border: 0;
  //       background-color: $logo_color;
  //     }
  //     &:hover{
  //       background-color: $green-color;
  //       color: $text_color;
  //     }
  //   }
  //   &.full{
  //     &.tier{
  //       .elem-select-switch{
  //         width: calc(calc(calc(100% - 2vh) / 3) - 4vh);
  //       }
  //     }

  //     &.half{
  //       .elem-select-switch{
  //         width: calc(calc(calc(100% - 1vh) / 2) - 4vh);
  //       }
  //     }
  //   }
  // }
</style>
