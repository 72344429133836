<template>
  <div class="applicant-search-entry radius box-shadow" @click="this.clickMethod">
    <div class="job-header">
      {{applicant.offerName}}
    </div>
    <div class="name-tag">
      <user-name-tag :user="applicant.applicant.user" :applied="applicant.applicant.createdAt" />
    </div>
  </div>
</template>

<script>
import UserNameTag from '@/components/EmployeeComponents/JobManagement/managementPartials/userNameTag.vue';

export default {
  name: 'ApplicantSearchEntry',
  props: ['applicant', 'clickMethod'],
  components: {
    UserNameTag,
  },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.applicant-search-entry{
  flex: auto 0;
  overflow: hidden;
  margin-right: 1.5vh;
  margin-bottom: 1.5vh;
  width: calc(calc(100% - 4.5vh) / 4);
  cursor: pointer;
  &:nth-child(4n){
    margin-right: 0;
  }
  .job-header{
    padding: 1vh;
    background-color: $fond_light_gray;
  }
  .name-tag{
    padding: .5vh;
  }
}

</style>
